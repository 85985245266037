.user-blogs-headings{
    background-color: #E4E3E3;
    text-align: center;
    padding: 7px 0;
    border-right: 2.3px solid #cfcece;
    font-size: 16px;
}

.user-blogs-info{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fbfafa;
    border-right: 2.3px solid #cfcece;
    border-bottom: 2.3px solid #cfcece;
    font-size: 14px;
    padding: 6px 10px;
}
  
.user-blogs-btn{
    text-align: center;
    border: 0;
    padding: 5px 15px;
    border-radius: 20px;
    color: white;
    width:120px
}

.user-blogs-ngt-btn{
    background-color: #ff0707;
}

.user-blogs-pst-btn{
    text-decoration: none;
    background-color: #85bf27;
}

.user-blogs-ngt-btn:hover{
    background-color: #dc0c0c;
}


.user-blogs-pst-btn:hover{
    text-decoration: none;
    color:white;
    background-color: #6dad07;
}