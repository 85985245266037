.products-page{
    /* font-family:'Roboto Serif', serif; */
}

.products-banner{
    height:400px;
    background: no-repeat center/cover url("../images/products-banner-new.png");
    margin: 0 !important;
    display: flex;
    justify-content: flex-end;
}

.products-banner div:nth-of-type(1){
    text-align: center;
    width: 40%;
    /* margin: 20px 50px; */
}

.products-banner-title{ 
    color: #fff;
    font-weight: 400;
    font-size:18px;
    line-height:25px;
    margin-bottom: 15px;
}

.products-banner-title span{
    border-bottom: 3px solid #fff;
}

.products-banner-text{
    font-size: 16px;
    font-weight: 400;
    padding: 0 30px;
    color: #fff;
}

.products-banner img{
    height: 400px;
    margin-right:50px;
}

.products-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F2F2F2;
    margin: 0 !important;
    padding: 20px;
    transition: 0.2s all;
} 

.products-section-bottom{
    height: 330px;

}

.products-section:hover{
    /* box-shadow: 0 10px 14px 5px rgb(98 98 98); */
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);
}

.products-section img{
    height: 150px;
}

.products-links{
    text-decoration: none;
    color: black;
}

.products-links:hover{
    color: black;
} 

.products-first-section{
    padding: 0 125px 0 0 !important;
}

.products-section-title{
    font-size: 16px;
    font-weight: 400;
    color: #9A0000;
    margin-bottom: 5px;
}

.products-section-info{
    font-size: 14px;
}

.products-section-2{
    margin: 0 !important;
}

@media (max-width:770px){
    .products-banner div:nth-of-type(1){
        width: 70%;
    }

    .products-first-section{
        padding: 0 !important;
    }

    .products-banner{
        justify-content: center;
    }

    
    .products-banner div:nth-of-type(1){
        width: 100%;
    }

    .products-section-bottom{
        height: 280px;
    }
}

@media (max-width:900px){

    .products-section img{
        height: 120px;
    }
}