.footer-section{
    margin-bottom: 20px;
    padding:15px 200px 0px; 
    background-color:#9A0000;
    width: 100%;
    /* font-family:'Roboto Serif', serif; */
}
 
.footer-column-container{
    display: flex;
    height: 100%; 
    min-width: 157px;
    margin-left: 20px;
}

.footer-columns{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #ffffff;
    font-size: 14px;
    height: 215px;
    padding: 0 !important;
}



.footer-columns div{
    margin: 0 0 10px 0;
}
 
.footer-section a{
    color: #ffffff;
    text-decoration: none;

}

.footer-section a:hover{
    color: #ffffff;
}

.footer-headings-border{
    border-bottom: 2px solid white;
}

.form-input{
    height: 52px;
    width: 100%;
    padding: 5px 15px;
    font-size: 17px;
    border-radius: 4px;
    outline: none;
    border: none;
}


.footer-logo{
    background: no-repeat center/contain url('../images/casewell-logo-new.png');
    height: 44px;
    width: 100%;
}

.footer-column-headings{
    font-size: 15px;
    font-weight: 700;
    padding-top: 10px;

}

.footer-column-headings{
    color: #ffffff;
    margin-bottom: 20px;
}

@media (max-width: 1200px){
    .footer-section{
        padding: 0 50px;
    }
}


@media (max-width: 992px){
    .footer-section{
        padding: 0 80px;
    }

    .footer-column-container{
        margin-left: 0px;
    }
    
}

@media (max-width: 992px){
    .footer-section{
        padding: 0 50px;
    }

    .footer-column-container{
        margin-left: 0px;
        min-width: unset;
    }
    .first-blog-content{
        margin-left:40px !important;
    }
}

@media (max-width: 360px){


    .footer-section{
        padding: 0 20px;
    }
}