.leadership-banner{
    background: no-repeat top/cover url('../images/leadership-banner.png');
    min-height: 550px;
    padding-right: 200px;
}

.leadership-banner div{
    font-weight: 600;
    color: white;
    font-size: 40px;
}


.leaders-info{
    display: flex;
    flex-direction: row;
    font-size: 18px;
    margin: 15px 100px;
    text-align: justify;
}

.leader-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.leader-title img{
    height: 200px;
}

.leader-about{
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0 50px;
}

.leader-about-title{
    font-weight: 600;
    font-size: 20px;
    color: #CE0002;
}

.leadership-about-written{
    margin-top:10px;
    font-size: 18;
    font-weight: 500;
}


.bottom-line{
    width:100%;
    height:3px;
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.bottom-line-ends{
    background-color: #CE0002;
    border-radius: 50%;
    height: 3px;
    width:3px;
}

.bottom-line-length{
    width:99%;
    height: 1px;
    background-color: #CE0002;
}

@media (max-width: 900px) {
    .leaders-info{
        flex-direction: column;
        margin: 30px 80px;
    }

    .leader-about{
        align-items: center;
        margin-top: 20px;
    }
}