.admincontactcontainer{
    min-height:90vh;
    padding:0 0 50px;
}

.contact-headings{
    background-color: #E4E3E3;
    text-align: center;
    padding: 7px 0;
    border-right: 2.3px solid #cfcece;
    font-size: 16px;
}
 
.contact-info{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fbfafa;
    border-right: 2.3px solid #cfcece;
    border-bottom: 2.3px solid #cfcece;
    font-size: 14px;
    padding: 6px 10px;

}

.contact-info::-webkit-scrollbar {
}




.message{
    max-height:100px;
    overflow: scroll;
}


@media (max-width:800px){
    .admincontactcontainer{
        width: 1400px;
        padding:0 0 50px;
    }
    
}