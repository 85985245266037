.home-pafe{
    /* font-family:'Roboto Serif', serif; */
} 

.dAeulA{
    width:100% !important;
}

.dTMfHp{
    line-height: 0 !important;
    height: 2rem !important;
    width:2rem !important;
}

.khxnmV img{ 
    height: 550px !important;
    object-fit: cover !important;
}
 
.banner{
    height:400px;
    /* margin:0px 0 10px;
    padding-top: 117px; */
    /* background: no-repeat center/cover url("../images/home-banner-new.jpg"); */
    position: relative;
    color: white;
    z-index: 2;
    margin-bottom: 20px;
}


.banner-images{
    height:450px;
    width: 100%;
    object-fit: cover;
}

.rsis-container div{
    background-position: center !important;
}

/* .rsis-image{
    background-position: center !important;
} */
 
#contact-btn{
    background-color: #9A0000;
    color: #fff;
    font-weight: 400;
    padding: 10px 23px;
    font-size: 16px;
    text-decoration: none;
}

#contact-btn:hover{
    background: #CF0406;
}

.inner-banner{
    position: absolute;
    top:90px;
    right: 120px;
    z-index: 5;
    height:200px;
    width: 25%;
    background: rgba(0, 0, 0, 0.3);
    /* margin-right: 60px ; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.inner-banner div:nth-of-type(1){
    font-size:18px;
    line-height:30px;
    margin: 0px 25px;
    text-align: center;
    padding-top: 10px;
}

.inner-banner div:nth-of-type(2){
    font-size:16px;
    font-weight:400;
    margin: 0 10px;
    text-align: center;
    line-height:20px;
    padding: 0 30px;
}

.home-banner-2 {
    position: absolute;
    top:0;
    bottom: 0; 
    left:0;
    right: 0;
    display: flex;
    justify-content: center;
}

.home-banner-2-img img{
    width: 100%;
    height: 100%;
}
.home-banner-2-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    padding: 30px 0px;
    min-height: 250px;
}


.second-banner-icon-img{
    height:50px;
    margin-bottom: 10px;
}

.second-banner-icon-img img{
    width:100%;
    height:100%;
}

.second-banner-border{
    display: flex;
    justify-content:center;
    align-items:center;
    width: 100%;
    margin-top: 10px;
}

.second-banner-border-line{
    width: 50%;
    height: 3px;
    margin-right: 10px;
    background-color: #9A0000;
}

.second-banner-border-img{
    width:9%;
}


@media (max-width:900px){
    .inner-banner{
        width: 40%;
    }


}

@media (max-width:750px){

    .inner-banner{
        top:120px;
        right: 16%;
        width: 66%;
        height: 150px;
    }

    .inner-banner div:nth-of-type(1){
        font-size: 14px;
        line-height: 20px;
    }

    .inner-banner div:nth-of-type(2){
        font-size: 12px;
        line-height: 17px;
        padding: 0 10px;

    }

    #contact-btn{
        background-color: #9A0000;
        color: #fff;
        font-weight: 400;
        padding: 5px 10px;
        font-size: 11px;
        text-decoration: none;
    }

    .banner button > img {
        width: 30px !important;
    }

    .banner > button{
        top: 0 !important;
    }
    
}