.blog-banner{
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
} 

.blog-banner-content{
    margin-left: 100px;
}
  
.blog-banner-category{
    width:125px;
    color: black;
    font-weight: 600;
    font-size: 13px;
    background-color: #D9D9D9;
    padding: 5px 30px;
    border-radius: 4px;
    margin-bottom: 20px;
    text-align: center;
}

.blog-banner-title{
    font-size: 18px;
    line-height:27px;
    color: white;
    margin-bottom: 30px;
    padding-bottom: 10px;
}

.blog-container{
    background: #F5F5F5;
}

.blog-username{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 36px;
    font-size: 16px;
    color:rgb(92, 92, 92);
    
}

.blog-date{
    display:flex;
    justify-content: flex-end;
    font-size: 16px;
    color: #CE0002;
    padding: 14px 36px;
}

#blogContainer{
    padding:20px 36px;
}

.related-post{
    width:150px;
    margin: 0 30px 30px 0;
    cursor:pointer;
}

.related-post img{
    height:90px;
    width:100%;
}



.related-post-title{
    font-weight: 600;
    font-size:11px;
    line-height: 12px;
    margin-top: 10px;
}



@media (max-width:600px){
    .blog-date, .blog-username{
        padding: 14px 0;
    }

}