.quality-page{
    /* font-family:'Roboto Serif', serif; */
} 

.quality-banner-1{
    background: no-repeat center/cover url("../images/quality.jpg");
    height: 400px;
    position: relative;
    margin: 0 !important;
}

.quality-banner-1-img {
    /* background: no-repeat center/cover url("../images/quality-banner-1-item.png"); */
    height: 550px;
}


.quality-banner-1-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
 

.quality-banner-1-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:  0px 0 0 60px ;
    width: 31%;
}



.quality-banner-1-content-title{
    font-size:18px;
    line-height:30px;
    text-align: center;
    color: white;
    margin-bottom: 0px;
}

.quality-banner-1-content-title span{
    border-bottom: 2px solid white;
}

.quality-banner-1-content-text{
    font-size:16px;
    font-weight:400;
    margin: 10px 10px 0;
    text-align: center;
    color: white;
}

.quality-info{
    padding: 20px;
}

.quality-section-div{
    background-color: #F2F2F2;
    margin-top: 20px;
    transition: 0.2s all;
}

.quality-section-div:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);
}

.quality-section-content{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding: 0 160px !important;
}

.quality-section-content div:nth-of-type(1){
    color: #9A0000;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 400;
}

.quality-section-content div:nth-of-type(2){
    font-size: 14px;
    text-align: justify;
}

.quality-section-content-item{
    display: flex;
    align-items: center; 
    justify-content: center;
}

.quality-section-content-item img{
    height:190px;
    margin: 15px 0;
}


.quality-banner-2-item{
    width: 498px;
}


@media (max-width: 1000px) {

    .quality-banner-2-item{
        width: 364px;
    }

    .quality-banner-1-content{
        width: 43%;
    }

    .quality-section-content{
        padding: 60px !important;
    }

    .quality-section-content-item img{
        height: 140px;
    }
}

@media (max-width: 600px) {

    .quality-banner-1-content{
        width: 70%;
    }

    .quality-banner-1-content{
        margin: 0 30px ;
    }

    .quality-section-content{
        padding: 20px !important;
    }

    .quality-section-content-item img{
        height: 140px;
    }


}

@media (max-width: 400px) {

    .quality-banner-1-content{
        width: 90%;
    }

    .quality-section-content-item img{
        height: 110px;
    }

    .quality-banner-2-item{
        width: 286px;
    }
}

@media (max-width: 300px) {
    .quality-section-content-item img{
        height: 80px;
    }

    .quality-banner-2-item{
        width: 208px;
    }
}