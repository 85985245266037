.capabilities-banner{
    height:400px;
    margin:0px 0 10px;
    background: no-repeat center/cover url("../images/capabilites.jpg");
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
  
.capabilities-inner-banner{
    height:210px;
    width: 50%;
    background: rgba(0, 0, 0, 0.3);
    margin-right: 60px ;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-weight: 500;
}

.capabilities-inner-banner div:nth-of-type(1){
    font-size:18px;
    line-height:35px;
    margin: 20px 50px;
    text-align: center;
    border-bottom: 3px solid white;
}

.capabilities-inner-banner div:nth-of-type(2){
    font-size:16px;
    margin: 0 10px;
    text-align: center;
    line-height: 30px;
}




.capabilites-container{
    padding:0 50px 50px;
} 

.capabilities-column{
    width: 420px;
    background-color: #F2F2F2;
    margin: 50px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    transition: 0.18s all;
}

.capabilities-column:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.1);

}


.capabilities-column-img{
    height:50px
}

.capabilities-column-heading{
    color: #9A0000;
    font-size: 16px;
    margin: 20px 0;
}

.capabilities-column-data{
    text-align: justify;
    font-size: 14px;
}




@media (max-width: 900px) {

    .capabilities-inner-banner{
        width: 80%;
    }

    .capabilities-banner{
        justify-content: center;
    }

    .capabilities-inner-banner{
        margin-right: 0;
    }
    .capabilities-inner-banner div:nth-of-type(1){
        line-height: 30px;
        margin: 15px;
    }
    .capabilities-inner-banner div:nth-of-type(2){
        line-height: 20x;
    }
}


@media (max-width: 700px) {
    .capabilities-inner-banner div:nth-of-type(1){
        line-height: 30px;
        margin: 15px;
    }
    .capabilities-inner-banner div:nth-of-type(2){
        font-size: 12px;
        line-height: 17px;
    }
}


@media (max-width: 700px) {
    .capabilites-container{
        padding: 0 ;
    }

    .capabilities-column{
        width: unset;
        margin: 10px 0;
    }
}
