.login-page{
    /* font-family:'Roboto Serif', serif; */
}

.login-header{
    height: 15px ;
    background-color: #9A0000;
} 

.casewell-logo-login{
    height:80px;
    margin-bottom: 70px;
}
 
.logic-section{
    background: no-repeat center/cover url("../images/login-background.png");
    font-size: 16px;

}

.login-field-input::-webkit-input-placeholder{
    font-size: 14px;
}

.login-field-label{
    padding-bottom: 5px;
}

.login-field-input{
    padding: 15px 65px 15px 20px;
    border: 2px solid #e4e4e474;
    outline: 0;
    background: #F5F5F5;
    border-radius: 10px;
    color:#838383;
    width: 400px;
}

.login-btn{
    border: 0;
    padding: 15px 35px;
    color: white;
    background: #9A0000;
    border-radius: 10px;
}

.login-btn:hover{
    background: #CF0406;
}

.login-footer{
    width: 100%;
}


.register-link{
    color: #9A0000;
    text-decoration: none;
    font-weight: 600;
}

.register-link:hover{
    color: #9A0000;
    text-decoration: underline;  
}


@media (max-width:470px){

    .login-field-input{
        width: 250px;
        padding: 15px 45px 15px 20px;
    }

}