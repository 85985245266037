.flash{
    border-radius: 0 !important;
    width: 100%;
    color: white !important;
    height: 50px;
    display: flex !important;
    padding: 0 20px !important;
    align-items: center !important;
    justify-content: space-between !important;
    font-size: 20px;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 50px;
  }

.flash-success{
    background-color: #009a15 !important;
}

.flash-error{
    background-color: #9A0000!important;
}

.flash-btn{
    position: none !important;
    padding: 0 !important;
    position: unset !important;
    /* top:30% !important;
    right: 10px !important; */
    background: none !important;
    opacity:1 !important;
    display: flex !important;
    align-items: center !important;
    height: 20px !important;
}