.aboutUs-page{
    /* font-family:'Roboto Serif', serif; */
} 

.aboutus-banner{
    background: no-repeat center/cover url("../images/aboutUs-banner.png");
    height:400px;
    margin: 0 !important;
}
 
.aboutus-banner-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:  0px 0 0 0;

}

.aboutus-banner-content div:nth-of-type(1){
    font-size:18px;
    line-height:18px;
    text-align: center;
    color: #9A0000;
    margin: 10px 0;
    border-bottom: 3px solid #9A0000;
}

.aboutus-banner-content div:nth-of-type(2){
    font-size:16px;
    font-weight:400;
    margin: 0 10px;
    text-align: center;
    padding: 0 80px;
}

.aboutus-mid-section{
    color: #9A0000;
    background-color: #F2F4F5;
    text-align: center;
    padding: 35px 170px;
    font-size: 24px;
    line-height: 29px;
    border-bottom: 5px solid #BAB9B9A6;
}

.aboutus-section{
    background: no-repeat center/cover url("../images/aboutUs-section.png");
    padding: 50px  ;
}

.aboutus-coreValues{
    background-color: #F2F2F2;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 370px;
    text-align: center;
    padding: 20px;
    border: 2px solid #9a9a9a;
    margin: 30px 20px 15px;
    border-radius: 15px;
    transition: 0.18s all;
}

.aboutus-coreValues:hover{
    /* box-shadow: 0 15px 15px 5px rgb(72, 72, 72); */
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.1);

}

.aboutus-section-title{
    font-size: 18px;
    color: #9A0000;
    text-align: center;
    margin-bottom: 10px;
}

.aboutus-section-title span{
    border-bottom: 3px solid #9A0000;
}

.aboutus-coreValues img{
    height:50px;
}

.aboutus-coreValues-title{
    color: #9A0000;
    margin: 15px 0 5px;
    font-size: 16px;
}

.aboutus-coreValues-info{
    font-size: 14px;
    /* margin-top: 10px; */
}




@media (max-width:800px){
    .aboutus-banner-content div:nth-of-type(1){
        font-size: 14px;
    }
    .aboutus-banner-content div:nth-of-type(2){
        font-size: 12px;
        padding: 10px 30px;
    }

    .aboutus-section{
        padding: 20px;
    }
}

@media (max-width:650px){
    .aboutus-coreValues{
        height: 240px;
        width: 246px;
        margin: 30px 0px 15px;
    
    }
    .aboutus-banner-content div:nth-of-type(2){
        font-size: 12px;
        padding: 0px;
    }

}

@media (max-width:400px){

    .aboutus-banner-content div:nth-of-type(2){
        font-size: 10px;
    }
}