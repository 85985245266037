.header-section{
    width: 100%;
}

.header-nav{
    /* background: #F5F5F5 ; */
    font-size: 14px;
    color: #000;
} 
 
.header-links{
    text-decoration: none;
    transition: 0.2s all;
    cursor: pointer;
    min-height: 80px; 
    /* width:98px; */
    color:black;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}




.nav-links-box{
    margin:0 10px;
}

.header-links:hover{
    color: #9A0000;
    border-bottom: 5px solid #9A0000;
}

.header-dropdown-column{
    position: relative;
    margin: 0 3px 0 6px !important;
} 

.navbar-case{
    margin-left: 0 !important;
}

.header-dropdown div{
    border-radius: 0 !important;
    border:0;
    background-color: rgb(49 50 38 / 50%) !important;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #B3B3B3;
    font-size: 14px !important;
    color: white;
    padding: 12px 15px;
    width: 139px;
    transition: 0.2s all;
}

.header-dropdown div:hover{
    background-color: rgba(255, 255, 255, 0.799) !important;
    color: black;
}

.header-dropdown a{
    text-decoration: none;
} 

.header-dropdown{
    border-radius: 0 !important;
    padding: 0 !important;
    border:0;
    background-color: rgb(49 50 38 / 50%) !important;
    position: absolute;
    top:80px;
    left:0;
    max-height: 0px;
    overflow: hidden;
} 

.header-dropdown-column:hover .header-dropdown{
    max-height: 2400px;
    overflow: visible;
}

.header-drop-down-blur{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right:0;
    z-index: 5;
    background-color: #CE0002;
    filter: blur(10px);
}

/* .header-dropdown-column input:checked ~ .header-dropdown{
    max-height: 2400px;
    overflow: visible;
} */



/* .header-dropdown-column:hover > .header-dropdown{
    max-height: 110px;
    overflow: visible;
} */

.header-blur{
    z-index: 2;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(10px);
}

.header-dropdown li{
    display: flex;
    align-items: center;

    height: 50px;
    border-bottom: 1px solid #B3B3B3;
    font-size: 18px;
}

.header-dropdown a{
    color:rgb(255, 255, 255)  ;
    z-index: 10;
}

.header-dropdown a:hover{
    color: rgb(255, 255, 255) ;
    background: none;
}




@media (max-width:992px){
    .header-links{
        justify-content: flex-start;
        min-height: 35px;
        padding:  0;
    }

    .header-dropdown{   
        top: 35px;
    }
}


@media (max-width:631px){
    .header-dropdown{   
        top: 35px;
    }
}