.accessories-banner-1{
    width: 900px;
    background: no-repeat top/cover url('../images/accessories-banner-bg.png');
    color: white;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
   

.accessories-banner-1 div:nth-of-type(1){
    font-size:18px;

    /* margin: 100px 0px 0px; */
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.accessories-banner-1 div:nth-of-type(1) span{
    border-bottom:3px solid #fff;
}

.accessories-banner-1 div:nth-of-type(2){
    font-size:16px;
    font-weight:400;
    margin: 0 10px;
    text-align: center;
}

.accessories-banner-2{
    width:100%;
    background: no-repeat bottom/contain url('../images/accessories-banner.png');
    display: flex;
    align-items: center;
    justify-content: center;
    height: 103.4%;
}

.accessories-banner-2 img{
    height: 230px;
}

.accessories-div{
    margin-top: 20px;
}

.accessories-info{
    display: flex;
    align-items: center;
    /* border-bottom: 2px solid #ff979b; */
    margin: 0 !important;
    transition: 0.2s all;
    background-color: #F2F2F2;
    padding: 20px;
}

.accessories-info:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);}

.accessories-info-title{
    text-align: center;
    font-size:16px;
    color: #9A0000;
    margin: 0 0 5px 0;
}

.accessories-info-content{
    text-align: justify;
    font-weight: 400;
    font-size:14px;
}

 .accessories-items-1{
    height:150px;
    margin: 20px 0;
}

.accessories-items-2{
    height:170px;
}

.accessories-items-3{
    height:180px;
} 

.accessories-section-footer{
    width: 100%;
}

.accessories-written-content{
    padding: 0 100px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.accessories-item-column{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width:770px){
    .accessories-banner-1{
        width: 100%;
        padding: 35px 6px;
    }

    .accessories-written-content{
        padding: 0 25px !important;
    }

    .accessories-banner-2 img{
        height: 150px;
    }

    .accessories-banner-1 div:nth-of-type(1){
        font-size: 14px;
    }

    .accessories-banner-1 div:nth-of-type(2){
        font-size: 14px;
    }
}


@media (max-width:500px){

}
