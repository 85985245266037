.notfound-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #9A0000;
    font-size: 44px;
    font-weight: 600;
    height: 646px;
    text-align: center;
    padding: 0 50px;
}