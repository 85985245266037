.submit-btn{
    background-color: #9A0000;
    color:white;
    border:0;
    padding: 8px 20px;
    font-size: 20px;
}

/* width */
.blog-content::-webkit-scrollbar {
    width: 0px;
}

.blog-form-error{
    color: #ff0000;
}
