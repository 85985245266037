body {
  margin: 0;
  background-color: #fff;
  font-family:'Roboto Serif', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
  padding: 0 20px;
}

#blogContainer p{
  margin: 0;
}

.jodit-wysiwyg{
  height: 300px !important;
}

.jodit-wysiwyg p{
  margin-bottom: 0;
  /* height: 20px;
  display: flex; */
  /* align-items: center; */
}

.jodit-wysiwyg li{
  height: 20px;

}

.jodit-workplace{
  min-height: 1000px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
