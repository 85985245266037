.contact-us-page{
    /* font-family:'Roboto Serif', serif; */
}

.contactUs-banner{
    display: flex;
    align-items: center;
    background: no-repeat top/cover url('../images/contactUs-banner.png');
    min-height: 400px;
}

.contactUs-content{
    display: flex;
    flex-direction:column; 
    color: white;
    margin-left: 230px;
}


.contactUs-content div:nth-of-type(1){
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
}

.contactUs-content div:nth-of-type(1) span{
    border-bottom: 3px solid white;

}

.contactUs-content div:nth-of-type(2){
    font-size: 16px;
}

/* --- new stlesf for first column */

.contact-us-input{
    width:100%;
    padding: 13px 15px;
    color:#626262;
    border: 1px solid rgb(187, 187, 187);
    font-weight: 300;
}



.contact-us-input:focus, .contact-us-input:hover{
    outline: 1px solid rgb(124, 124, 124);
}


.contact-us-input::-webkit-input-placeholder{
    color:rgb(102, 102, 102);
    font-weight: 300;
    font-size: 14px;
}
.input-labels{
    margin-bottom: 2px;
    font-weight: 300;
    font-size: 16px;
}

.submit-button{
    background-color: #9A0000;
    color:#fff;
    font-weight: 600;
    padding: 10px 20px;
    border: 0;
    outline:0;
    margin-bottom: 10px;

}


#privacyLink{
    text-decoration: none;
    color:#000;
}

#privacyLink:hover{
    color:#9A0000;
    border-bottom:3px solid #9A0000;
}

.contactus-form-error{
    color: #ff0000;
}

.contact-link{
    color: rgb(123, 123, 123) !important;
    text-decoration: none !important;
}

.contact-link:hover{
    color:#9A0000 !important;
    text-decoration: underline !important;
}


.contactUs-second-column{
    padding:50px 0px 0 100px !important;
}

.contactUs-second-column-div{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width:300px;
}

.privacy-policy{
    display: flex;
    justify-content: flex-end;
    font-weight:600;
    font-size: 12px;
}

.contactUs-logos{
    background-color: #9A0000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    box-sizing: border-box;
}

.contactUs-logos:hover{
    background-color: #fff;
    border: 3px solid #CF0406;
    
}

.contactUs-logos img{
    height: 30px;
}

.contactUs-man{
    height: 45px;
    margin: 0 8px 0 4px;
}

@media (max-width: 1100px) {
    .contactUs-second-column{
        padding:50px 0px !important;

        display: flex;
        justify-content: center;
    }
}

@media (max-width: 900px) {
    .contactUs-second-column-div{
        width: 250px;
    }
}

@media (max-width: 800px) {
    .contactUs-banner{
        display: flex;
        justify-content: center;
    }

    .contactUs-content{
        margin-left: 0;
    }

    .privacy-policy{
        justify-content: center;
    }

}


