.centralizer-banner-1{
    background: no-repeat center/cover url("../images/centralizer-banner-1.png");
    height: 400px;
    position: relative;
    margin: 0 !important;
}

.centralizer-banner-1-img {
    height: 200px;
    /* padding-top: 100px; */
}

.centralizer-banner-1-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
}

.centralizer-banner-1-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:  0px 50px;

}

.centralizer-banner-1-content div:nth-of-type(1){
    font-size:18px;
    line-height:25px;
    text-align: center;
    font-weight: 400;
    color: #9A0000;
    border-bottom: 3px solid #9A0000;
    margin-bottom: 10px;
}

.centralizer-banner-1-content div:nth-of-type(2){
    font-size:16px;
    font-weight:400;
    margin: 0 10px;
    text-align: center;
}

.centralizer-section-div{
    margin-top: 20px;
}

.centralizer-section{
    padding: 20px 100px;
    background-color: #F2F2F2;
    margin: 0 !important;
    transition: 0.2s all; 
}

.centralizer-section:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);
}

.centralizer-section-content{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    /* padding-right: 200px; */
}



.centralizer-section-content div:nth-of-type(1){
    color: #9A0000;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
}

.centralizer-section-content div:nth-of-type(2){
    font-size: 14px;
    text-align: justify;
}

.centralizer-items{
    display: flex;
    justify-content: center;
    align-items: center;
}



.centralizer-info-img-1{
    height: 170px;
    margin: 20px 0;
}


.centralizer-info-img-2{
    height: 250px;
}

.centralizer-info-img-3{
    height: 200px;
}

@media (max-width: 1050px) {
    .centralizer-info-img-1{
        height: 120px;
    }
     
    
    .centralizer-info-img-2{
        height: 170px;
    }
    
    .centralizer-info-img-3{
        height: 150px;
    }
}



@media (max-width: 600px) {
    .centralizer-banner-1-img {
        height: 170px;
    }

    .centralizer-banner-1-content{
        padding: 0 20px 10px;
    } 

    .centralizer-section{
        padding: 20px 30px;
    }

    .centralizer-section:nth-of-type(2n+1){
        flex-direction: column-reverse;
    }

    .centralizer-info-img-1{
        height: 100px;
    }
     
    
    .centralizer-info-img-2{
        height: 200px;
    }
    
    .centralizer-info-img-3{
        height: 150px;
    }
}

@media (max-width: 400px) {
    .centralizer-banner-1-content div:nth-of-type(2){
        font-size: 14px;
    }


    .centralizer-info-img-1{
        height: 80px;
    }
     
    
    .centralizer-info-img-2{
        height: 140px;
    }
    
    .centralizer-info-img-3{
        height: 120px;
    }
}
