.stopCollar-banner-1{
    width: 900px;
    background: no-repeat top/cover url('../images/stop-collar-banner-1.png');
    color: white;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
 
 
.stopCollar-banner-1 div:nth-of-type(1){
    font-size:18px;

    /* margin: 100px 0px 0px; */
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;

}

.stopCollar-banner-1 div:nth-of-type(1) span{
    border-bottom: 3px solid #fff;
}

.stopCollar-banner-1 div:nth-of-type(2){
    font-size:16px;
    font-weight:400;
    margin: 0 10px;
    text-align: center;
}

.stopCollar-banner-2{
    width:100%; 
    background: no-repeat bottom/contain url('../images/stop-collar-banner.png');
    display: flex;
    align-items: center;
    justify-content: center;
    height: 103.65%;
}

.stop-collar-banner-img{
    height: 250px;
}

.stop-collar-div{
    margin-top: 20px;
}

.stop-collar-info{
    display: flex;
    align-items: center;
    /* border-bottom: 2px solid #ff979b; */
    margin: 0 !important;
    transition: 0.2s all;
    background-color: #F2F2F2;
    padding: 20px 0;
}

.stop-collar-info:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);}

.stop-collar-info-title{
    text-align: center;
    font-size:16px;
    color: #9A0000;
    margin: 0 0 5px 0;
}

.stop-collar-info-content{
    text-align: justify;
    font-weight: 400;
    font-size:14px;
}

.stop-collar-items-1{
    height:160px;
}

.stop-collar-items-2{
    height:150px;
}

.stop-collar-items-3{
    height:170px;
}

.stop-collar-items-4{
    height:150px;

}

.stop-collar-section-footer{
    width: 100%;
}

.stop-collar-written-content{
    padding: 0 100px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stop-collar-item-column{
    display: flex;
    align-items: center;
    justify-content: center;
}

.stop-collar-border{
    background-color: #9A0000;
    height:3px;
    margin: 0 100px;
}



@media (max-width: 770px) {
    .stopCollar-banner-1{
        width: 100%;
        padding: 35px 40px;
    }

    .stop-collar-banner-img{
        height: 160px;
    }

    .stop-collar-written-content {
        padding: 0 25px !important;
    }

}

@media (max-width: 500px) {

    .stop-collar-items-1{
        height:130px;
    }
    
    .stop-collar-items-2{
        height:120px;
    }
    
    .stop-collar-items-3{
        height:130px;
    }
    
    .stop-collar-items-4{
        height:120px;
    
    }

    .stopCollar-banner-1 {
        padding: 20px 10px;
    }


    .stopCollar-banner-1 div:nth-of-type(1){
        font-size: 14px;
    }

    .stopCollar-banner-1 div:nth-of-type(2){
        font-size: 14px;
    }
    .stop-collar-banner-img {
        height: 130px;
    }
}