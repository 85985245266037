.floats-banner-1{
    background: no-repeat center/cover url("../images/floats-banner-1.png");
    height: 400px;
    position: relative;
    margin: 0 !important;
}
 
.float-banner-1-img {
    /* height: 100%; */
}

.float-banner-1-img  img{
    height: 250px;
}
 
.floast-banner-1-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
 
.floast-banner-1-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:  0px 50px;

}

.floast-banner-1-content div:nth-of-type(1){
    font-size:18px;
    line-height:25px;
    text-align: center;
    color: #9A0000;
    border-bottom: 3px solid #9A0000;
    margin-bottom: 10px;
}

.floast-banner-1-content div:nth-of-type(2){
    font-size:16px;
    font-weight:400;
    text-align: center;
    padding: 0 170px;
}

.floats-section{
    padding: 50px 100px;
    margin: 0 !important;
    background-color: #F2F2F2;
    transition: 0.2s all;
    margin-top: 20px !important;
}

.floats-section:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);
}

.float-section-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-right: 200px; */
}

.float-section-content-img{
    height: 180px;
}

.float-section-content div:nth-of-type(1){
    color: #9A0000;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500; 
}

.float-section-content div:nth-of-type(2){
    font-size: 14px;
    text-align: justify;
}

@media (max-width: 1200px) {
    .floast-banner-1-content div:nth-of-type(2){
        padding: 0 60px;
    }
    .floats-section{
        padding: 20px ;
    }
    
}

@media (max-width: 600px) {


    /* .float-section-content-img{
        margin-top: 20px;
    } */

    .floast-banner-1-content{
        padding: 0px 20px;
    }

    .floast-banner-1-content div:nth-of-type(2){
        padding: 0;
    }

    .float-banner-1-img img{
        height: 190px;
    }
}