.privacyPolicy-banner{
    background: no-repeat center/cover url('../images/privacyPolicy-banner.png');
    height: 400px;
}

.privacyPolicy-banner div{
    font-size:18px;
    color:white;
    font-weight: 400;
    border-bottom: 3px solid white;
}

.privacyPolicy-content{
    margin:20px 100px;
    font-size: 14px;
    font-weight: 400;
}

.privacyPolicy-para{
    margin-top: 20px;
    text-align: justify;
}

.privacyPolicy-heading{
    color:#9A0000;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 3px;
}

.privacyPolicy-links{
    color: #9A0000 !important;
}