.ourJourney-banner{
    background: no-repeat top/cover url('../images/ourJourney-banner.png');
    min-height: 400px;
    padding-left: 200px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}
 
.ourJourney-banner div{
    font-weight: 400;
    color: white;
    font-size: 18px;
    border-bottom: 3px solid white;
}

.journey-section{ 
    margin: 0 !important;
}

.journey-column-container{
    padding: 0;
    padding-right: 25px;
}

.journey-columns{
    display: flex;
    flex-direction: column;
    align-items: center; 
    position: relative;
    width: 100%;
    transition: 0.2s all;
}

.journey-columns:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);
}


.journey-sub-headings{
    color: #9A0000;
    font-size: 16px;
    font-weight: 600;
    /* margin-bottom: 50px; */
}

.journey-sub-headings span{
    border-bottom: 3px solid #9A0000;
}

.journey-col-footer{
    /* height: 50px; */
    position: absolute;
    bottom: 0;
    width: 100%;
}

.journey-text{
    text-align: center;
    /* line-height: 20px; */
    border-bottom: 1px dotted black;
    /* padding-bottom: 50px; */
    font-size: 14px ;
    padding: 0 50px;
}

.journey-col-1-item-1{
    height: 250px;
}

.journey-col-1-item-2{
    height:150px;
    margin:50px 0 30px;
}


.journey-col-3-item-1{
    height: 125px;
}
.journey-column-1{
    background-color: #EEEEEE;
}

.journey-column-2{
    background-color: #DEDDDD;
}

.journey-column-3{
    background-color: #E7E7E7;
}

.journey-column-4{
    background-color: #D1D0D0;
}

@media (max-width:1200px){

    .journey-sub-headings{
        font-size: 14px;
    }

    .journey-text{
        font-size: 12px;
        padding: 0 25px;
    }
    .ourJourney-banner{
        padding-left: 0;
        justify-content: center;
    }

    .journey-col-1-item-1{
        height: 150px;
    }
    
    .journey-col-1-item-2{
        height: 100px;
    }

    .journey-col-3-item-1{
        height: 85px;
    }
}

@media (max-width:768px){
    .journey-column-container{
        padding: 0 !important;
        padding-bottom: 20px !important;
    }
}
