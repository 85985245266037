.csr-page{
    /* font-family:'Roboto Serif', serif; */
}

.csr-banner{
    background: no-repeat center/cover url("../images/csr-banner-new.png");
    min-height: 400px;
    position: relative;
    margin: 0 !important;
}


.csr-banner-img {
    height: 270px;
}



.csr-banner-content{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding-right: 150px;
} 

.csr-banner-content div:nth-of-type(1){
    font-size:18px;
    line-height:50px;
    text-align: center; 
    font-weight: 400;
    color: #9A0000;
    margin: 30px 0px 10px;
}

.csr-banner-title-underline{
    border-bottom: 3px solid #9A0000;;
}
 
.csr-banner-content div:nth-of-type(2){
    font-size:16px;
    font-weight:400;
    margin: 0 10px;
    text-align: center;
    color: #000;
    padding: 0 100px;
}

.csr-section{
    /* background: no-repeat center/cover url("../images/csr-section-banner.png"); */
    display: flex;
    flex-direction: column;
}

.csr-info{
    background-color: rgb(228 228 228 / 67%);
    margin: 0 !important;
    margin-top:20px !important;
    padding: 30px 100px;
    transition: 0.2s all;
}

.csr-info:hover{
    box-shadow: 0 10px 20px 2px #9A0000;
    cursor: pointer;
    transform: scale(1.015);
}
.csr-item-column{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.csr-section-img-title{
    font-size: 16px;
    color: #9A0000;
    font-weight: 400;
    border-bottom: 3px solid #9A0000;
}

.csr-written-content{
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: justify;
}


@media (max-width: 970px) {
    .csr-banner-content{
        padding: 0 0 20px;
    }

    .csr-banner-img {
        transform: scale(0.8);
    }

    .csr-banner-content div:nth-of-type(1){
        margin: 10px 0;
        line-height: 30px;
    }
    
    .csr-banner-content div:nth-of-type(2) {
        padding: 0 20px;
    }

    .csr-info {
        padding: 20px;
    }

    .csr-written-content{
        margin: 20px 0;
    }
    
}